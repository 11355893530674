import * as React from 'react';
import { Link } from 'gatsby';
import {kebabCase} from 'lodash';
import { render } from 'storyblok-rich-text-react-renderer';
import {FluidImage } from '../gatsby-image';
import {
  PostCardModernWrapper,
  PostPreview,
  PostDetails,
  PostDate,
  PostTitle,
  Excerpt,
  PostTags,
  PostMonth,
} from './post-card-modern.style';

interface PostCardModernProps {
  image?: any;
  title: string;
  description?: string;
  url: string;
  date?: string;
  tags?: [];
  className?: string;
  placeholderBG?: string;
}

const PostCardModern: React.FunctionComponent<PostCardModernProps> = ({
  image,
  title,
  description,
  url,
  date = '',
  tags,
  className,
  placeholderBG,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['post_card_modern'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  const [day, month] = !!date ? date.split('/') : [];

  return (
    <PostCardModernWrapper className={addAllClasses.join(" ")} {...props}>
      {
        <PostPreview className="post_preview">
          <Link to={`/${url}`}>
            <FluidImage blok={image} />
          </Link>
          {date && (
            <PostDate className="post_date">
              {day}
              <PostMonth>{month}</PostMonth>
            </PostDate>
          )}
        </PostPreview>
      }

      <PostDetails className="post_details">
        {tags == null ? null : (
          <PostTags className="post_tags">
            {tags.map((tag: string, index: number) => (
              <Link key={index} to={`/tags/${kebabCase(tag)}/`}>
                {`#${tag}`}
              </Link>
            ))}
          </PostTags>
        )}
        <PostTitle className="post_title">
          <Link to={`/${url}`}>{title}</Link>
        </PostTitle>
        {description && (
          <Excerpt
            className="excerpt"
          >
            {render(description)}
          </Excerpt>
        )}
      </PostDetails>
    </PostCardModernWrapper>
  );
};

export default PostCardModern;
