import * as React from 'react';
import { graphql } from 'gatsby';
import PostCardModern from '../components/post-card-modern/post-card-modern';
import Newsletter from '../components/newsletter/newsletter';
import Pagination from '../components/pagination/pagination';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BlogPostsWrapper, PostRow, PostGrid } from './templates.style';
import setPlaceholderColor from '../utils/setPlaceholderColor';
import parseContent from '../utils/parseContent';

const BlogList = (props: any) => {
  const { data } = props;
  const Posts = data.blogPosts.nodes;
  const newsLetter = parseContent(data.newsletter.nodes[0].content);

  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/page/1' : `/page/${(currentPage - 1).toString()}`;
  const nextPage = `/page/${(currentPage + 1).toString()}`;
  const PrevLink = !isFirst && prevPage;
  const NextLink = !isLast && nextPage;

  return (
    <Layout>
      <SEO title={`Page ${currentPage}`} />

      <BlogPostsWrapper>
        <PostRow>
          {Posts.map((post: any) => {
            // Random Placeholder Color

            const color = setPlaceholderColor();
            const content = parseContent(post.content);

            return (
              <PostGrid>
                <PostCardModern
                  key={post.id}
                  title={post.name}
                  image={content.banner_image}
                  url={post.full_slug}
                  description={content.description}
                  date={post.published_at}
                  tags={post.tag_list}
                  placeholderBG={color}
                />
              </PostGrid>
            );
          })}
        </PostRow>
        <Pagination
          prevLink={PrevLink}
          nextLink={NextLink}
          currentPage={`${currentPage}`}
          totalPage={`${numPages}`}
        />
      </BlogPostsWrapper>
      <Newsletter {...newsLetter} />
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage {
      path
    }
    newsletter: allStoryblokEntry(filter: {field_component: {eq: "newsletter"}}) {
        nodes {
          id
          content
        }
      }
    blogPosts: allStoryblokEntry(
        filter: {field_component: {eq: "blog_post"}}
        limit: $limit
        skip: $skip
      ) {
        nodes {
          id
          content
          tag_list
          full_slug
          name
          tag_list
          published_at(formatString: "DD/MMM")
        }
      }
  }
`;
